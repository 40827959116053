
@font-face {
    font-family: Brandon_bld;
    src: url(./font/Brandon_bld.otf) format("opentype");
}

.App {
  max-width: 1600px;
  margin: 0  auto;
}

:root {
  --mt-green: #739e9e;
}

header { color: #7dc73c}

header,
footer {
  padding: 2em;
}
header h1 {
  font-family:  Brandon_bld, 'sans-serif';
  font-size:  1em;
  margin:  0;
}
header h2 {
  color: black;
  font-size: 3.5em;
}

header img {
  max-width: 170px;
  height: auto;
  margin-bottom: 0.7em;
}

header .main-top {
  margin-top: 0.7em;
  color:  black;
}


.main-bottom {
  padding:  2em;
}

.arrow-right {
  width: .5em;
  display: inline-block;
  margin-left: .5em;
  height: 0;
  border-top: 0.4em solid transparent;
  border-bottom: 0.4em solid transparent;
  border-left: .5em solid var(--mt-green);
}


.gruppen_liste {
  padding: 1em 4em;
  background-color: var(--mt-green);
  display: flex;
  flex-wrap: wrap;
}

.gruppen_logo {
  background-color: #fff;
  border-radius: 10px;
  width: 230px;
  height: 230px;
  margin: 1em;
  box-shadow: 3px 3px 12px #00000030;
  max-width: 100%; max-height: 100%;
  display: flex;
  transition: all .2s ease-in-out; 
}

.gruppen_logo:hover {
  box-shadow: 3px 3px 12px #000000bf;
  cursor: pointer;
  transform: scale(1.05); 
}

.gruppen_logo_inner_text {
  margin: auto;
  font-weight: bold;
}

.gruppen_liste a {
  color: #000;
  text-decoration: none;
  height: calc( 230px + 2em );
}

.header_group_page { 
  display: flex;
  justify-content: space-between;
 }

.header_group_page img { 
  max-width: 170px;
  height: auto;
}

.header_group_page a { 
  color: var(--mt-green);
  font-weight: bolder;
  cursor: pointer;
}

.group_page  {
  color: rgb(123 122 122);
}

.group_page h1 { text-align: center }

.group_page.logo_container {
  display: flex;
  min-height: 400px;
  background-position: center;
  background-size: cover;
}
.group_page .logo {
  display: block;
  margin: auto;
  background-color: #fff;
  width: 200px;
  height: 200px;
}

.group_page  {
  padding: 2em 4em; 
  font-size: 1.1em;
}

.group_page.topic {
  padding: 2em; 
  font-weight: bolder;
}

.group_page.topic p ,
.group_page.contact p {
  margin: .5em 0;
}

.group_page h1 { font-size: xx-large }

.group_page.about h1,
.group_page.contact h1 { color: rgb(248 190 168); }
.group_page.meet h1 { color: #fff; }
.group_page.meet  { background-color: rgb(248 190 168); }
.group_page.contact  { padding: 2em 4em; }
.group_page.contact  > div { display: flex; }
.group_page.contact  p { text-align: left; }

.group_page.contact input[type=submit] {
  background-color: var(--mt-green);
}

footer {color: #000;}
footer nav {
  display: flex;
  justify-content: space-between;
}

footer nav a {
  color: black;
  text-decoration: none;
  font-size: x-large;
  font-weight: bolder;
}

footer nav a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px){

  .group_page.contact  { padding: 1em 1em; }

  .group_page.contact  > div { display: block; }

  footer nav {
    flex-direction: column;
  }
}

@media screen and (max-width: 465px){
  header h1 {
    font-size:1.8em;
  }

  .gruppen_liste {
    flex-direction: column;
    align-content: space-around;
  }

  .group_page  {
    padding: 1.2em 1em; 
  }
}




